// @flow
import React from 'react';
import { Create, SimpleForm, TextInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import RoleLabels from './labels';

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="target" label={RoleLabels.target} />
      <ArrayInput source="permissions" label="Permissions">
        <SimpleFormIterator newValue={() => ""}>
          <TextInput label="Berechtigung" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="types" label="Werte">
        <SimpleFormIterator newValue={() => ""}>
          <TextInput label="Wert" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
);
