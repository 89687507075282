// @flow

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Button as RAButton, showNotification, fetchStart, fetchEnd } from 'react-admin';
import ActionImport from '@material-ui/icons/GetApp';
import ActionSearch from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import {httpClient} from '../../config/dataProvider';
import config from '../../config/config';


type Props = {
  showNotification: (message: string, type: string) => void,
  fetchStart: () => void,
  fetchEnd: () => void,
  push: (url: string) => void
};
type State = {
  open: boolean,
  users: Array,
  term: string,
  loading: boolean,
  searchPending: boolean
};

class UserImport extends React.Component<Props, State> {
  props: Props;
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    fetchStart: PropTypes.func.isRequired,
    fetchEnd: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  };

  _timeoutHandle: number = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      users: [],
      term: '',
      loading: false,
      searchPending: false
    };
  }

  onImportClick() {
    this.setState({open: true});
  }

  handleClose() {
    this.setState({ open: false, users: [], loading: false, searchPending: false, term: '' });
  }

  onInputChange(e) {
    this.setState({
      term: e.target.value
    });
    this.startTimeout();
  }

  clearTimeout() {
    if (this._timeoutHandle) {
      window.clearTimeout(this._timeoutHandle);
    }
  }

  startTimeout() {
    this.clearTimeout();
    this._timeoutHandle = window.setTimeout(this.search.bind(this), 250);
    this.setState({ searchPending: true });
  }

  async search() {
    try {
      this.props.fetchStart();
      this.setState({users: [], loading: true});
      const response = await httpClient(config.baseUrl + '/ldap/search?term=' + encodeURI(this.state.term), { method: 'get' });
      const users = response.json;
      this.setState({users, loading: false, searchPending: false});
      console.log(users);
    } catch(e) {
      console.error('Error while fetching users.', e);
      this.props.showNotification('Error: An error occured while fetching users.', 'warning');
    } finally {
      this.props.fetchEnd();
      this.setState({loading: false, searchPending: false});
    }
  }

  async onUserClick(username: string) {
    try {
      this.props.fetchStart();
      const response = await httpClient(config.baseUrl + '/ldap/create?username=' + encodeURI(username), { method: 'post' });
      const user = response.json;
      this.props.showNotification('Der Benutzer wurde erfolgreich importiert.', 'info');
      this.handleClose();
      this.props.push(`/users/${user.id}/show`);
    } catch(e) {
      console.error('Error while importing user.', e);
      let error = 'Ein Fehler ist beim Importieren des Benutzers aufgetreten.';
      if (e.status === 409) {
        error = 'Der Benutzer existiert bereits.';
      } else if (e.status === 404) {
        error = 'Der Benutzer konnte nicht gefunden werden.';
      }
      this.props.showNotification(error, 'warning');
    } finally {
      this.props.fetchEnd();
    }
  }

  renderList() {
    if (this.state.loading) {
      return (
        <CircularProgress />
      );
    } else if (this.state.users.length === 0 && !this.state.searchPending && this.state.term !== '') {
      return (
        <Typography
          color="textSecondary"
          component="p"
          style={{textAlign: 'center', margin: '20px 5px', fontStyle: 'italic'}}
        >
          Es konnten keine Benutzer gefunden werden.
        </Typography>
      )
    } else {
      return (
        <List>
          {
            this.state.users.map(u => (
              <ListItem button key={`item_${u.username}`}>
                <ListItemText primary={u.displayName} secondary={u.username} onClick={this.onUserClick.bind(this, u.username)} />
              </ListItem>
            ))
          }
        </List>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <RAButton
          onClick={this.onImportClick.bind(this)}
          label={"Importieren"}
          key="button"
        >
          <ActionImport />
        </RAButton>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose.bind(this)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Benutzer importieren"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Suche nach einem Benutzer um ihm Rechte und Rollen zuweisen zu können.
            </DialogContentText>
            <div style={{ display: 'flex' }}>
              <Input
                style={{ flex: 1 }}
                onKeyUp={this.onInputChange.bind(this)}
                placeholder="Namen oder Email eingeben..."
                startAdornment={
                  <InputAdornment position="start">
                    <ActionSearch />
                  </InputAdornment>
                }
              />
            </div>
            {this.renderList()}
            <DialogContentText>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  showNotification,
  fetchStart,
  fetchEnd,
  push
})(UserImport);
