// @flow
import React from 'react';
import PropTypes from 'prop-types';

const populateList = (entries) => {
  return (entries || []).map((entry) => (
    <li key={entry.toString()}>
      {entry}
    </li>
    ));
};

const ArrayListField = ({source, record = {}}) =>
  <ul className="array-list-field">
    {
      populateList(record[source])
    }
  </ul>;


ArrayListField.defaultProps = {
  addLabel: true,
  label: 'List'
};


ArrayListField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string
};

export default ArrayListField;
