// @flow
import React from 'react';
import {List, Datagrid, TextField, DateField, BooleanField} from 'react-admin';
import AlertLabels from './labels';

export default (props) => (
  <List {...props} title={AlertLabels.model} exporter={false}>
    <Datagrid rowClick="show">
      <BooleanField source="visible" label={AlertLabels.visible} />
      <TextField source="level" label={AlertLabels.level} />
      <TextField source="message" label={AlertLabels.message} />
      <DateField showTime source="showAt" label={AlertLabels.showAt} />
      <DateField showTime source="hideAt" label={AlertLabels.hideAt} />
      <DateField showTime source="createdAt" label={AlertLabels.createdAt} />
      <DateField showTime source="updatedAt" label={AlertLabels.updatedAt} />
    </Datagrid>
  </List>
);
