// @flow
import React from 'react';
import {Show, SimpleShowLayout, TextField, SelectField, DateField, BooleanField} from 'react-admin';
import AlertLabels from './labels';

export default (props) => (
  <Show {...props} title="Alert anzeigen">
    <SimpleShowLayout>
      <BooleanField source="visible" label={AlertLabels.visible} />
      <SelectField source="level" label={AlertLabels.level} choices={AlertLabels.choices} />
      <TextField source="message" label={AlertLabels.message} />
      <DateField showTime source="showAt" label={AlertLabels.showAt} />
      <DateField showTime source="hideAt" label={AlertLabels.hideAt} />
      <DateField showTime source="createdAt" label={AlertLabels.createdAt} />
      <DateField showTime source="updatedAt" label={AlertLabels.updatedAt} />
    </SimpleShowLayout>
  </Show>
);
