// @flow
import React from 'react';
import { Resource } from 'react-admin';
import RoleList from './RoleList';
import RoleEdit from './RoleEdit';
import RoleCreate from './RoleCreate';
import RoleShow from './RoleShow';
import GroupIcon from '@material-ui/icons/Group';

export default (
  <Resource
    name="roles"
    options={{ label: 'Rollen' }}
    icon={GroupIcon}
    list={RoleList}
    edit={RoleEdit}
    create={RoleCreate}
    show={RoleShow}
  />
);
