// @flow
import React from 'react';
import {Create, SimpleForm, SelectInput, TextInput, BooleanInput, DateTimeInput } from 'react-admin';
import AlertLabels from './labels';

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <BooleanInput source="visible" label={AlertLabels.visible} />
      <SelectInput source="level" label={AlertLabels.level} choices={AlertLabels.choices} />
      <TextInput multiline source="message" label={AlertLabels.message} />
      <DateTimeInput type="datetime-local" source="showAt" label={AlertLabels.showAt} />
      <DateTimeInput type="datetime-local" source="hideAt" label={AlertLabels.hideAt} />
    </SimpleForm>
  </Create>
);
