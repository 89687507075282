// @flow
import React from 'react';
import { Resource } from 'react-admin';
import UserList from './UserList';
import UserEdit from './UserEdit';
import PersonIcon from '@material-ui/icons/Person';
import UserShow from './UserShow';
import UserCreate from './UserCreate';

export default (
  <Resource
    name="users"
    icon={PersonIcon}
    options={{ label: 'Benutzer' }}
    list={UserList}
    edit={UserEdit}
    show={UserShow}
    create={UserCreate}
  />
);
