// @flow
import React from "react";
import { Edit, SimpleForm, TextInput, ArrayInput } from "react-admin";
import RoleLabels from "./labels";
import SimpleFormIterator from "../../components/SimpleFormIterator";

const roleEdit = (props) => (
  <Edit {...props} title="Rolle bearbeiten">
    <SimpleForm redirect="show">
      <TextInput source="name" label={RoleLabels.name} />
      <TextInput
        multiline
        source="description"
        label={RoleLabels.description}
      />
      <ArrayInput source="permissions" label="Permissions">
        <SimpleFormIterator newValue={() => ""}>
          <TextInput label="Berechtigung" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="types" label="Werte">
        <SimpleFormIterator newValue={() => ""}>
          <TextInput label="Wert" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="rootOrgUnit" label="Default-Root" />
      <TextInput source="rootOrgUnits.overview" label="Overview-Root" />
      <TextInput source="rootOrgUnits.dashboard" label="Dashboard-Root" />
      <TextInput source="rootOrgUnits.stoppdetail" label="Stopp-Detail-Root" />
    </SimpleForm>
  </Edit>
);

export default roleEdit;
