// @flow
import React from 'react';
import { Create, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import UserLabels from './labels';

export default (props) => (
  <Create title="Benutzer erstellen" {...props}>
    <SimpleForm redirect="show">
      <TextInput source="username" label={UserLabels.username} />
      <TextInput source="displayName" label={UserLabels.displayName} />
      <TextInput source="email" label={UserLabels.email} />
      <TextInput disabled source="provider" defaultValue="local" label={UserLabels.provider} />
      <ReferenceArrayInput source="roles" reference="roles" label={UserLabels.roles}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput source="password" label={UserLabels.password} type="password" />
    </SimpleForm>
  </Create>
);
