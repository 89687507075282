// @flow
import React from "react";
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  RichTextField,
  DateField,
} from "react-admin";
import RoleLabels from "./labels";
import ArrayListField from "../../components/ArrayListField";

const roleShow = (props) => (
  <Show {...props} title="Rolle anzeigen">
    <TabbedShowLayout>
      <Tab label="Informationen">
        <TextField source="id" label={RoleLabels.id} />
        <TextField source="name" label={RoleLabels.name} />
        <RichTextField source="description" label={RoleLabels.description} />
        <DateField showTime source="createdAt" label={RoleLabels.createdAt} />
        <DateField showTime source="updatedAt" label={RoleLabels.updatedAt} />
      </Tab>
      <Tab label="Berechtigungen">
        <TextField source="rootOrgUnit" label="Default-Root" />
        <TextField source="rootOrgUnits.overview" label="Overview-Root" />
        <TextField source="rootOrgUnits.dashboard" label="Dashboard-Root" />
        <TextField
          source="rootOrgUnits.stoppdetail"
          label="Stopp-Detail-Root"
        />
        <ArrayListField source="permissions" label="Permissions" />
        <ArrayListField source="types" label="Werte" />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default roleShow;
