// @flow
import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import RoleLabels from './labels';

export default (props) => (
  <List {...props} title={RoleLabels.model} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" label={RoleLabels.id} />
      <TextField source="name" label={RoleLabels.name} />
      <DateField showTime source="createdAt" label={RoleLabels.createdAt} />
    </Datagrid>
  </List>
);
