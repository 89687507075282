// @flow
const UserLabels = {
  model: "Benutzer",
  username: "Benutzername",
  displayName: "Name",
  email: "E-Mail",
  password: "Passwort",
  ldapTitle: "LDAP Titel",
  provider: "Art",
  roles: "Rollen",
  createdAt: "Erstellt",
  updatedAt: "Zuletzt geändert",
  lastLoginAt: "Zuletzt eingeloggt",
};

export default UserLabels;
