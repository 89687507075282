// @flow
import React from 'react';
import { Resource } from 'react-admin';
import PermissionList from './PermissionList';
import PermissionEdit from './PermissionEdit';
import PermissionCreate from './PermissionCreate';
import PermissionShow from './PermissionShow';
import PermDataSetting from '@material-ui/icons/PermDataSetting';

export default (
  <Resource
    name="permissions"
    options={{ label: 'Default Permissions' }}
    icon={PermDataSetting}
    list={PermissionList}
    edit={PermissionEdit}
    create={PermissionCreate}
    show={PermissionShow}
  />
);
