// @flow
const RoleLabels = {
  model: "Rollen",
  id: "ID",
  name: "Name",
  description: "Beschreibung",
  createdAt: "Erstellt",
  updatedAt: "Zuletzt geändert"
};

export default RoleLabels;
