// @flow
import { AUTH_LOGIN, AUTH_ERROR, AUTH_LOGOUT, AUTH_CHECK } from 'react-admin';
import config from './config';

export default (type: string, params: Object) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request(`${config.baseUrl}/login`, {
      method: 'POST',
      headers: new Headers({ 'Authorization': 'Basic ' + btoa(`${username}:${password}`) }),
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        const authorization = response.headers.get('Authorization');
        if (authorization) {
          const token = response.headers.get('Authorization').replace(/Bearer /, '');
          sessionStorage.setItem('jwtToken', token);
        } else {
          throw new Error('Authorization failed');
        }
      });
  }
  if (type === AUTH_LOGOUT) {
    sessionStorage.removeItem('jwtToken');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status  = params.status;
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('jwtToken');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    return sessionStorage.getItem('jwtToken') ? Promise.resolve() : Promise.reject();
  }
  return Promise.reject('Not implemented');
}
