// @flow
import React from 'react';
import { List, Datagrid, TextField, DateField, EmailField, CardActions, CreateButton, Filter, TextInput } from 'react-admin';
import UserLabels from './labels';
import UserImport from './UserImport';


const Actions = ({
                       bulkActions,
                       basePath,
                       currentSort,
                       displayedFilters,
                       exporter,
                       filters,
                       filterValues,
                       onUnselectItems,
                       resource,
                       selectedIds,
                       showFilter,
                       total
                     }) => (
  <CardActions>
    {bulkActions && React.cloneElement(bulkActions, {
      basePath,
      filterValues,
      resource,
      selectedIds,
      onUnselectItems,
    })}
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    }) }
    <CreateButton basePath={basePath} />
    <UserImport />
  </CardActions>
);

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Suche" source="q" alwaysOn />
  </Filter>
);

export default (props) => (
  <List {...props} title={UserLabels.model} exporter={false} actions={<Actions />} filters={<UserFilter />}>
    <Datagrid rowClick="show">
      <TextField source="username" label={UserLabels.username} />
      <TextField source="displayName" label={UserLabels.displayName} />
      <TextField source="provider" label={UserLabels.provider} />
      <EmailField source="email" label={UserLabels.email} />
      <DateField showTime source="lastLoginAt" label={UserLabels.lastLoginAt} />
      <DateField showTime source="createdAt" label={UserLabels.createdAt} />
    </Datagrid>
  </List>
);
