// @flow
import React from 'react';
import {Resource} from 'react-admin';
import AlertList from './AlertList';
import AlertEdit from './AlertEdit';
import AlertCreate from './AlertCreate';
import AlertShow from './AlertShow';
import GroupIcon from '@material-ui/icons/Group';

export default (
  <Resource
    name="alerts"
    options={{label: 'Alerts'}}
    icon={GroupIcon}
    list={AlertList}
    edit={AlertEdit}
    create={AlertCreate}
    show={AlertShow}
  />
);
