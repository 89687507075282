// @flow
import React from "react";
import {
  ArrayInput,
  Edit,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import UserLabels from "./labels";
import SimpleFormIterator from "../../components/SimpleFormIterator";

const userEdit = (props) => (
  <Edit title="Benutzer bearbeiten" {...props}>
    <SimpleForm redirect="show">
      <TextInput source="displayName" label={UserLabels.displayName} />
      <TextInput source="email" label={UserLabels.email} />
      <ReferenceArrayInput
        source="roles"
        reference="roles"
        label={UserLabels.roles}
        perPage={100}
        sort={{
          field: "name",
          order: "ASC",
        }}
      >
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput
        source="password"
        label={UserLabels.password}
        type="password"
      />
      <ArrayInput source="permissions" label="Permissions">
        <SimpleFormIterator newValue={() => ""}>
          <TextInput label="Berechtigung" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="types" label="Werte">
        <SimpleFormIterator newValue={() => ""}>
          <TextInput label="Wert" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput source="rootOrgUnit" label="Default-Root" />
      <TextInput source="rootOrgUnits.overview" label="Overview-Root" />
      <TextInput source="rootOrgUnits.dashboard" label="Dashboard-Root" />
      <TextInput source="rootOrgUnits.stoppdetail" label="Stopp-Detail-Root" />
    </SimpleForm>
  </Edit>
);

export default userEdit;
