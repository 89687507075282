// @flow
import React, {Component} from 'react';
import {Admin, Login} from 'react-admin';
import germanMessages from 'ra-language-german';
import dataProvider from './config/dataProvider';
import authProvider from './config/authProvider';
import UserResource from './resources/user';
import RoleResource from './resources/role';
import AlertResource from './resources/alert';
import PermissionResource from './resources/permission';

const i18nProvider = () => germanMessages;

const LoginPage = () => <Login backgroundImage="https://source.unsplash.com/random/1600x900" />;

class App extends Component {
  render() {
    return (
      <Admin dataProvider={dataProvider} i18nProvider={i18nProvider} authProvider={authProvider} loginPage={LoginPage}>
        {UserResource}
        {RoleResource}
        {PermissionResource}
        {AlertResource}
      </Admin>
    );
  }
}
export default App;
