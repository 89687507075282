// @flow
import React from "react";
import { Show, SimpleShowLayout, TextField, DateField } from "react-admin";
import PermissionLabels from "./labels";
import ArrayListField from "../../components/ArrayListField";

const permissionShow = (props) => (
  <Show {...props} title="Rolle anzeigen">
    <SimpleShowLayout>
      <TextField source="id" label={PermissionLabels.id} />
      <TextField source="target" label={PermissionLabels.target} />
      <DateField
        showTime
        source="createdAt"
        label={PermissionLabels.createdAt}
      />
      <DateField
        showTime
        source="updatedAt"
        label={PermissionLabels.updatedAt}
      />
      <TextField source="rootOrgUnit" label="Default-Root" />
      <TextField source="rootOrgUnits.overview" label="Overview-Root" />
      <TextField source="rootOrgUnits.dashboard" label="Dashboard-Root" />
      <TextField source="rootOrgUnits.stoppdetail" label="Stopp-Detail-Root" />
      <ArrayListField source="permissions" label="Permissions" />
      <ArrayListField source="types" label="Werte" />
    </SimpleShowLayout>
  </Show>
);

export default permissionShow;
