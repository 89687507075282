const productionConfig = {
  baseUrl: 'https://backend-api-production.pub.prd.lma.hermesgermany.digital'
};

const stagingConfig = {
  baseUrl: 'https://backend-api-staging.pub.prd.lma.hermesgermany.digital'
};

const developmentConfig = {
  baseUrl: 'https://backend-api-development.pub.prd.lma.hermesgermany.digital'
};

const defaultConfig = {
  baseUrl: 'http://localhost:9000'
};

const environments = {
  'production': productionConfig,
  'staging': stagingConfig,
  'development': developmentConfig,
};

const hosts = {
  'backend-development.pub.prd.lma.hermesgermany.digital': 'development',
  'backend-staging.pub.prd.lma.hermesgermany.digital': 'staging',
  'backend-production.pub.prd.lma.hermesgermany.digital': 'production',
  'backend-ui-development.pub.prd.lma.hermesgermany.digital': 'development',
  'backend-ui-staging.pub.prd.lma.hermesgermany.digital': 'staging',
  'backend-ui-production.pub.prd.lma.hermesgermany.digital': 'production',
  'hdbi-backend-devteam.hermesgermany.digital': 'development',
  'hdbi-backend-staging.hermesgermany.digital': 'staging',
  'hdbi-backend.hermesgermany.digital': 'production'
};

const hostname = window.location.hostname;
const environment = hosts[hostname] || 'development';

export default environments[environment];
