// @flow
import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import config from './config';

export const httpClient = (url: string, options: Object = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = sessionStorage.getItem('jwtToken');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

export default simpleRestProvider(config.baseUrl, httpClient);
