// @flow
import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import PermissionLabels from './labels';

export default (props) => (
  <List {...props} title={PermissionLabels.model} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" label={PermissionLabels.id} />
      <TextField source="target" label={PermissionLabels.target} />
      <DateField showTime source="createdAt" label={PermissionLabels.createdAt} />
      <DateField showTime source="updatedAt" label={PermissionLabels.updatedAt} />
    </Datagrid>
  </List>
);
