// @flow
const AlertLabels = {
  model: "Alerts",
  id: "ID",
  level: "Level",
  message: "Message",
  createdAt: "Erstellt",
  updatedAt: "Zuletzt geändert",
  showAt: "Anzeigen ab",
  hideAt: "Anzeigen bis",

  visible: "Anzeigen in UI",
  choices: [{id: 'info', name: 'Info'}, {id: 'warning', name: 'Warning'}, {id: 'danger', name: 'Danger'}]
};

export default AlertLabels;