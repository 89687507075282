// @flow
const PermissionLabels = {
  model: "Permissions",
  id: "ID",
  target: "Target",
  createdAt: "Erstellt",
  updatedAt: "Zuletzt geändert"
};

export default PermissionLabels;
