// @flow
import React from 'react';
import { Create, SimpleForm, TextInput } from 'react-admin';
import RoleLabels from './labels';

export default (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="id" label={RoleLabels.id} />
      <TextInput source="name" label={RoleLabels.name} />
      <TextInput multiline source="description" label={RoleLabels.description} />
    </SimpleForm>
  </Create>
);
