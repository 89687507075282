// @flow
import React from 'react';
import {Edit, SimpleForm, SelectInput, TextInput, BooleanInput, DateField, DateTimeInput} from 'react-admin';
import AlertLabels from './labels';

export default (props) => (
  <Edit {...props} title="Alert bearbeiten">
    <SimpleForm redirect="show">
      <BooleanInput source="visible" label={AlertLabels.visible} />
      <SelectInput source="level" label={AlertLabels.level} choices={AlertLabels.choices} />
      <TextInput multiline source="message" label={AlertLabels.message} />
      <DateTimeInput type="datetime-local" source="showAt" label={AlertLabels.showAt} />
      <DateTimeInput type="datetime-local" source="hideAt" label={AlertLabels.hideAt} />
      <DateField showTime source="createdAt" label={AlertLabels.createdAt} />
      <DateField showTime source="updatedAt" label={AlertLabels.updatedAt} />
    </SimpleForm>
  </Edit>
);
